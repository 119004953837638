import Actions from "../../constants";
import { getKeycloakToken } from "lib/util";
import debounce from "lodash.debounce";

export const uploadDocument = (data: any) => ({
  type: Actions.UPLOAD_DOCUMENT,
  payload: data,
  token: getKeycloakToken(),
});

export const fetchDocumentsInfo = () => {
  return {
    type: Actions.FETCH_DOCUMENTS_INFO,
    token: getKeycloakToken(),
  };
};

export const fetchDocumentsInfoFulfilled = () => ({
  type: Actions.FETCH_DOCUMENTS_INFO_FULFILLED,
});

export const fetchDocumentsUsage = () => ({
  type: Actions.FETCH_DOCUMENTS_USAGE,
  token: getKeycloakToken(),
});

export const setDocumentsInfo = (data: any) => ({
  type: Actions.SET_DOCUMENTS_INFO,
  payload: data,
});
export const setDocumentsUsage = (data: any) => ({
  type: Actions.SET_DOCUMENTS_USAGE,
  payload: data,
});

export const setIndexingDocuments = (data: any) => ({
  type: Actions.SET_INDEXING_DOCUMENTS,
  payload: data,
});

export const updateDocument = (data: any) => ({
  type: Actions.UPDATE_DOCUMENT,
  payload: data,
  token: getKeycloakToken(),
});

export const updateDocumentFulfilled = () => ({
  type: Actions.UPDATE_DOCUMENT_FULFILLED,
});

export const fetchDocument = (
  documentId: string,
  hideSnackbar?: boolean,
  decodedSegment?: any,
  pageNumber?: any,
  metadata?: any
) => ({
  type: Actions.FETCH_DOCUMENT,
  document_id: documentId,
  hideSnackbar: hideSnackbar,
  decodedSegment: decodedSegment,
  pageNumber: pageNumber,
  metadata: metadata,
  token: getKeycloakToken(),
});

export const fetchDocumentFulfilled = (
  decodedSegment?: any,
  pageNumber?: any,
  metadata?: any
) => ({
  type: Actions.FETCH_DOCUMENT_FULFILLED,
  decodedSegment: decodedSegment,
  pageNumber: pageNumber,
  metadata: metadata,
});

export const fetchDocumentFailed = (error: any) => ({
  type: Actions.FETCH_DOCUMENT_FAILED,
  error: error,
});

export const setDocument = (data: any) => ({
  type: Actions.SET_DOCUMENT,
  payload: data,
});

export const deleteDocument = (documentId: string) => ({
  type: Actions.DELETE_DOCUMENT,
  document_id: documentId,
  token: getKeycloakToken(),
});
export const deleteDocuments = (documentIds: any, llm_model?: string) => ({
  type: Actions.DELETE_DOCUMENTS,
  document_ids: documentIds,
  llm_model: llm_model,
  token: getKeycloakToken(),
});

export const deleteDocumentFulfilled = () => ({
  type: Actions.DELETE_DOCUMENT_FULFILLED,
});

export const fetchDownloadDocument = (documentId: string) => ({
  type: Actions.DOWNLOAD_DOCUMENT,
  document_id: documentId,
  token: getKeycloakToken(),
});

export const fetchDocumentDownloadFulfilled = () => ({
  type: Actions.DOWNLOAD_DOCUMENT_FULFILLED,
});

export const fetchDocumentDownloadFailed = (error: any) => ({
  type: Actions.DOWNLOAD_DOCUMENT_FAILED,
  error,
});

export const setDownloadDocument = (data: any) => ({
  type: Actions.SET_DOWNLOAD_DOCUMENT,
  payload: data,
  token: getKeycloakToken(),
});

export const documentSearch = (data: any) => ({
  type: Actions.DOCUMENT_SEARCH,
  payload: data,
  token: getKeycloakToken(),
});

export const setDocumentViewport = (data: any) => ({
  type: Actions.SET_DOCUMENT_VIEWPORT,
  payload: data,
});

export const fetchUserConversations = (payload?: {
  documentId?: string | undefined | null;
  collectionId?: string | undefined | null;
  llm_model?: string | undefined | null;
}) => {
  return {
    type: Actions.FETCH_USER_CONVERSATIONS,
    documentId: payload && payload.documentId ? payload.documentId : null,
    collectionId: payload && payload.collectionId ? payload.collectionId : null,
    llm_model: payload && payload.llm_model ? payload.llm_model : null,
    token: getKeycloakToken(),
  };
};

export const fetchUserConversationsFulfilled = () => ({
  type: Actions.FETCH_USER_CONVERSATIONS_FULFILLED,
});

export const fetchUserConversationsFailed = (error: any) => ({
  type: Actions.FETCH_USER_CONVERSATIONS_FAILED,
  error,
});
export const setUserConversations = (data: any) => ({
  type: Actions.SET_USER_CONVERSATIONS,
  payload: data,
});

export const upsertUserConversations = (
  data: any,
  documentId: string | null | undefined,
  editChatId?: string | null | undefined,
  collectionId?: string | null | undefined,
  llm_model?: string | null | undefined,
  created_by?: string | null | undefined
) => ({
  type: Actions.UPSERT_USER_CONVERSATIONS,
  payload: data,
  documentId: documentId ? documentId : null,
  editChatId: editChatId ? editChatId : null,
  collectionId: collectionId ? collectionId : null,
  llm_model: llm_model ? llm_model : null,
  created_by: created_by ? created_by : null,
  token: getKeycloakToken(),
});

export const setDocumentConversationId = (data: any) => ({
  type: Actions.SET_DOCUMENT_CONVERSATION_ID,
  payload: data,
});
export const setSelectedCopilotChat = (data: any) => ({
  type: Actions.SET_SELECTED_COPILOT_CHAT,
  payload: data,
});
export const setNewCreatedChatId = (data: any) => ({
  type: Actions.SET_NEW_CREATED_CHAT_ID,
  payload: data,
});
export const setLoadingNewDocument = (data: any) => ({
  type: Actions.SET_LOADING_NEW_DOCUMENT,
  payload: data,
});
export const setCopilotAiConfig = (data: any) => ({
  type: Actions.SET_COPILOT_AI_CONFIG,
  payload: data,
});
export const setCopilotColorMode = (data: any) => ({
  type: Actions.SET_COPILOT_COLOR_MODE,
  payload: data,
});

export const resetDocumentViewFetchStatus = () => ({
  type: Actions.RESET_DOCUMENT_VIEW_FETCH_STATUS,
});

export const setPdfData = (data: any) => ({
  type: Actions.SET_PDF_DATA,
  payload: data,
});
export const setMessageLimitReached = (data: any) => ({
  type: Actions.SET_MESSAGE_LIMIT_REACHED,
  payload: data,
});

export const setAnnotations = (data: any) => ({
  type: Actions.SET_ANNOTATIONS,
  payload: data,
});

export const setAnnotationsHighlightColor = (data: any) => ({
  type: Actions.SET_ANNOTATIONS_HIGHLIGHTCOLOR,
  payload: data,
});
export const setStorageLimitReached = (data: any) => ({
  type: Actions.SET_STORAGE_LIMIT_REACHED,
  payload: data,
});
export const manuallyIndexDocument = (data: string) => ({
  type: Actions.MANUALLY_INDEX_DOCUMENT,
  payload: data,
  token: getKeycloakToken(),
});
export const manuallyIndexDocuments = () => ({
  type: Actions.MANUALLY_INDEX_DOCUMENTS,
  token: getKeycloakToken(),
});

export const fetchDocumentsCount = () => {
  return {
    type: Actions.FETCH_DOCUMENTS_COUNT,
    token: getKeycloakToken(),
  };
};
export const setDocumentsCount = (data: any) => ({
  type: Actions.SET_DOCUMENTS_COUNT,
  payload: data,
});
