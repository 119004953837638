import React, { useEffect, useRef, useState } from "react";
import dynamic from "next/dynamic";
import { useTranslations } from "next-intl";
import {
  CompositeDecorator,
  ContentState,
  convertFromRaw,
  convertToRaw,
  EditorState,
} from "draft-js";
import ResponseContentTypeChoice, {
  CONTENT_TYPE,
} from "./ResponseContentTypeChoice";
import PlaceholderImage from "../../../../components/PlaceholderImage";
import Paper from "@mui/material/Paper";
import MediaDialog from "./MediaDialog";
import AtomicBlockUtils from "draft-js/lib/AtomicBlockUtils";
import { useSelector } from "react-redux";
import { useStoreState } from "store/hooks";
import { usePrevious } from "hooks/usePrevious";
import ResponseEditorLinkMenu from "features/dialogue-editor/components/ResponseEditorLinkMenu";
import RefContext from "components/context/RefContext";
import Box from "@mui/material/Box";

const Editor = dynamic(
  () => import("react-draft-wysiwyg").then((mod) => mod.Editor),
  { ssr: false }
);

const editorStateFromValue = (v) => {
  try {
    return EditorState.createWithContent(
      typeof v === "string" ? ContentState.createFromText(v) : convertFromRaw(v)
    );
  } catch (e) {
    return EditorState.createEmpty();
  }
};

export const isMediaBlock = (v) => {
  if (v.blocks && Array.isArray(v.blocks) && v.blocks) {
    return (
      v.blocks.filter((e) => e.text === " " && e.type === "atomic").length !== 0
    );
  }
};

const findWithRegex = (word, contentBlock, callback) => {
  const text = contentBlock.getText();

  const matches = [...text.matchAll(new RegExp(word, "gim"))];
  matches.forEach((match) => {
    if (match.index !== undefined)
      return callback(match.index, match.index + match[0].length);
  });
};

const Decorated = ({ children }) => {
  return <mark>{children}</mark>;
};
const ResponseEditor = (props) => {
  const {
    value,
    index,
    editorClassName,
    onChange,
    onClearState,
    isResponseFocused,
    readOnly = false,
    isDragging = false,
    clearState = false,
    alwaysShowToolbar = false,
    timedAutoSave = true,
    showList = true,
    controlledMode = true,
  } = props;
  const t = useTranslations();
  const timerRef = useRef(null);
  const [editorState, setEditorState] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [isFocused, setIsFocused] = useState(isResponseFocused);
  const [contentType, setContentType] = useState(CONTENT_TYPE.MESSAGE);
  const [mediaModalOpen, setMediaModalOpen] = useState(false);
  const [media, setMedia] = useState("");
  const channelLimitations = useSelector(
    (state) => state.channelLimitations || null
  );
  const language = useStoreState((state) => state.language);
  const query = useStoreState((state) => state.dialogueNodeSearchQuery);
  const previousQuery = usePrevious(query);
  const myRef = useRef(null);

  const save = (state) => {
    if (!state) state = editorStateFromValue(value);
    clearTimeout(timerRef.current);
    onChange(convertToRaw(state.getCurrentContent()));
  };

  const onEditorStateChange = (state) => {
    setEditorState(state);
    clearTimeout(timerRef.current);
    if (timedAutoSave) {
      timerRef.current = setTimeout(() => {
        save(state);
      }, 10000);
    } else {
      save(state);
    }
  };

  const getMediaSrc = (v) => {
    if (v.entityMap) {
      return v.entityMap[0]["data"]["src"];
    }
  };

  const changeContentType = (v) => {
    if (isMediaBlock(v)) {
      setMedia(getMediaSrc(v));
      setContentType(CONTENT_TYPE.MEDIA);
    } else {
      setMedia("");
      setContentType(CONTENT_TYPE.MESSAGE);
    }
  };

  useEffect(() => {
    if (clearState) {
      setMedia("");
      setContentType(CONTENT_TYPE.MESSAGE);
      onClearState();
    }
  }, [clearState]);

  useEffect(() => {
    if (index !== currentIndex) {
      setCurrentIndex(index);
      changeContentType(value);
      setEditorState(editorStateFromValue(value));
    } else {
      if (!controlledMode) return;
      if (editorState && !isDragging && isFocused) {
        setEditorState(
          EditorState.forceSelection(
            editorStateFromValue(value),
            editorState.getSelection()
          )
        );
      } else {
        changeContentType(value);
        setEditorState(editorStateFromValue(value));
      }
    }
  }, [value]);

  useEffect(() => {
    if (media && !value) {
      const emptyState = EditorState.createEmpty();
      const entityKey = emptyState
        .getCurrentContent()
        .createEntity("IMAGE", "MUTABLE", {
          src: media,
        })
        .getLastCreatedEntityKey();

      const newEditorState = AtomicBlockUtils.insertAtomicBlock(
        emptyState,
        entityKey,
        " "
      );
      setEditorState(newEditorState);
      save(newEditorState);
    }
  }, [media, value]);

  useEffect(
    () => () => {
      clearTimeout(timerRef.current);
    },
    []
  );

  useEffect(() => {
    if (isDragging) {
      save(editorState);
    }
  }, [isDragging]);

  const showResponseContentTypeChoice = (element) => {
    const channelAllowsMedia =
      channelLimitations &&
      channelLimitations?.responses?.content_types?.media?.enabled === true;
    const isEmpty =
      !element ||
      (element["blocks"] &&
        element["blocks"].length === 1 &&
        element["blocks"][0]["text"] === "");
    return isEmpty && !media && channelAllowsMedia;
  };

  const handleSelectContentType = (newValue) => {
    setContentType(newValue);
  };

  const renderMedia = () => {
    if (media) {
      return <img alt="media" src={media} />;
    } else {
      return (
        <Paper sx={{ display: "flex" }}>
          <PlaceholderImage
            width={200}
            height={100}
            onClick={() => setMediaModalOpen(true)}
          />
          <MediaDialog
            open={mediaModalOpen}
            onClose={(file) => {
              setMediaModalOpen(false);
              setMedia(file);
            }}
          />
        </Paper>
      );
    }
  };

  const handleStrategy = (contentBlock, callback) => {
    if (query) {
      findWithRegex(query, contentBlock, callback);
    }
  };

  const decorator = {
    strategy: handleStrategy,
    component: Decorated,
  };

  // this together with renderQueryResult forces rerenders of the Editor component to inject updated customDecorators
  useEffect(() => {
    setEditorState((old) => {
      if (old) {
        return EditorState.set(old, {
          decorator: new CompositeDecorator([decorator]),
        });
      }
    });
  }, [query]);

  const renderQueryResult = () => query === previousQuery;

  return (
    <>
      {contentType === CONTENT_TYPE.MESSAGE && renderQueryResult() && (
        <RefContext.Provider value={myRef}>
          <Box ref={myRef}>
            <Editor
              placeholder={t("dialogue_response_placeholder")}
              toolbar={{
                options: [
                  "inline",
                  ...(showList ? ["list"] : []),
                  "link",
                  "history",
                ],
                inline: {
                  options: ["bold", "italic"],
                },
                link: {
                  inDropdown: false,
                  className: undefined,
                  component: ResponseEditorLinkMenu,
                  popupClassName: undefined,
                  dropdownClassName: undefined,
                  options: ["link"],
                },
              }}
              localization={{
                locale: language,
              }}
              customDecorators={[decorator]}
              toolbarHidden={
                alwaysShowToolbar ? false : !isResponseFocused || !isFocused
              }
              spellCheck={true}
              readOnly={readOnly}
              editorState={editorState}
              editorClassName={editorClassName}
              stripPastedStyles={false}
              onEditorStateChange={onEditorStateChange}
              toolbarOnFocus={(event) => {}}
              onFocus={(event) => {
                setIsFocused(true);
              }}
              onBlur={(event) => {
                if (!myRef.current.contains(event.relatedTarget)) {
                  setIsFocused(false);
                  save(editorState);
                }
              }}
            />
          </Box>
        </RefContext.Provider>
      )}
      {contentType === CONTENT_TYPE.MEDIA && renderMedia()}
      {showResponseContentTypeChoice(props.value) && (
        <ResponseContentTypeChoice
          handleSelectContentType={handleSelectContentType}
          selected={contentType}
        />
      )}
    </>
  );
};

export default ResponseEditor;
