import React, { useEffect, useState } from "react";
import getConfig from "lib/config";
import {
  SSRCookies,
  SSRKeycloakProvider,
  useKeycloak,
} from "@react-keycloak/ssr";
import PageBackdrop from "../common/PageBackdrop";
import { useRouter } from "next/router";
import { firstLetterCapitalize, getHumanAvatarBaseEncoded } from "lib/util";
import useUserGroups from "../../hooks/useUserGroups";
import { useKeycloakUserRoles } from "hooks/useUserRoles";
import { UserContext } from "../context/UserContext";
import { ROLES } from "lib/constants";
import { logger } from "components/helper";

const { publicRuntimeConfig } = getConfig();

export const getKeycloakConfig = () => ({
  url: publicRuntimeConfig.REACT_APP_KEYCLOAK_URL,
  realm: publicRuntimeConfig.REACT_APP_KEYCLOAK_REALM,
  clientId: publicRuntimeConfig.REACT_APP_KEYCLOAK_CLIENT_ID,
});

export const keycloakInitOptions = {
  onLoad: publicRuntimeConfig.REACT_APP_KEYCLOAK_ONLOAD,
};

const onKeycloakTokens = (tokens: any) => {
  if (tokens) {
    if (localStorage && tokens["token"])
      localStorage.setItem("keycloakToken", tokens["token"]);
  }
};

export const adminRoutes = ["contacts", "campaigns", "settings", "nlu-inbox"];

const KeycloakAC = (props: any) => {
  const groups = useUserGroups();
  const roles = useKeycloakUserRoles();
  const router = useRouter();

  const { keycloak, initialized } = useKeycloak();
  const { query, pathname } = router;
  const { tenant = "" }: { tenant?: string } = query;

  const [allowed, setAllowed] = useState(false);
  useEffect(() => {
    if (
      initialized &&
      groups !== undefined &&
      roles?.includes(ROLES.DASHBOARD_ACCESS)
    ) {
      if (tenant) {
        if (groups?.includes(firstLetterCapitalize(tenant))) {
          // TODO redirect if user has no valid role for requested route
          // const pathnameSplit = pathname.split("/");
          // if (
          //   !roles?.includes(ROLES.ADMIN) &&
          //   pathnameSplit.some((r) => adminRoutes.includes(r))
          // ) {
          //   router
          //     .push({
          //       pathname: `/`,
          //     })
          //     .then((e) => e);
          // }
          // else {
          setAllowed(true);
          // }
        } else {
          setAllowed(false);
          // @ts-ignore
          keycloak?.logout();
        }
      } else {
        const fallbackTenant = groups.includes("Standard")
          ? "standard"
          : groups[0].toLowerCase() || "standard";

        if (pathname === "/") {
          query.tenant = fallbackTenant;
          router.push(`/${fallbackTenant}`).then((r) => r);
        } else {
          query.tenant = fallbackTenant;
          router.push({ pathname, query }).then((r) => r);
        }
      }
    }

    // custom IDPs setup in keycloak
    if (
      publicRuntimeConfig.REACT_APP_KEYCLOAK_ONLOAD === "check-sso" &&
      initialized &&
      keycloak?.authenticated === false
    ) {
      if (firstLetterCapitalize(tenant) === "Bots4you") {
        // staging pmb 3
        // @ts-ignore
        keycloak?.login({ idpHint: "azure-bots4you" });
      } else if (firstLetterCapitalize(tenant) === "Ulrichmedical54324") {
        // release platform
        // @ts-ignore
        keycloak?.login({ idpHint: "azure-ulrichmedical" });
      } else if (firstLetterCapitalize(tenant) === "Abcfinance63384") {
        // release platform
        // @ts-ignore
        keycloak?.login({ idpHint: "azure-abcfinance" });
      } else if (firstLetterCapitalize(tenant) === "Ggg43432") {
        // release platform
        // @ts-ignore
        keycloak?.login({ idpHint: "adfs-ggg43432" });
      } else if (firstLetterCapitalize(tenant) === "Goetz45925") {
        // release platform
        // @ts-ignore
        keycloak?.login({ idpHint: "goetz45925" });
      } else if (firstLetterCapitalize(tenant) === "Wbgnuernberg37227") {
        // release platform
        // @ts-ignore
        keycloak?.login({ idpHint: "adfs-wbgnuernberg37227" });
      } else if (firstLetterCapitalize(tenant) === "Bietigheimerwb25247") {
        // release platform
        // @ts-ignore
        keycloak?.login({ idpHint: "bietigheimerwb25247" });
      } else if (firstLetterCapitalize(tenant) === "Ifgroup93245") {
        // release platform
        // @ts-ignore
        keycloak?.login({ idpHint: "ifgroup93245" });
      } else {
        // @ts-ignore
        keycloak?.login();
      }
    }

    if (initialized && keycloak?.authenticated) {
      const minimumValidity = 30; // Token should be valid for at least another 30 seconds
      const intervalId = setInterval(() => {
        keycloak
          .updateToken(minimumValidity)
          .then((refreshed) => {
            if (refreshed) {
              const newToken = keycloak.token;
              if (localStorage) {
                if (typeof newToken === "string") {
                  localStorage.setItem("keycloakToken", newToken);
                  logger.log("localStorage was successfully updated");
                }
              }
            } else {
              logger.log(
                `Token is still valid for at least ${minimumValidity} more seconds`
              );
            }
          })
          .catch((error) => {
            logger.error(
              "Failed to refresh the token, or the session has expired",
              error
            );
          });
      }, 1000 * 60);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [tenant, router, groups, roles, initialized, keycloak]);

  if (allowed) {
    return (
      <UserContext.Provider
        value={{
          state: {
            user: {
              // @ts-ignore
              id: keycloak.idTokenParsed?.sub,
              // @ts-ignore
              name: keycloak.idTokenParsed?.name || "",
              avatar: getHumanAvatarBaseEncoded(),
              roles: roles || [], // keycloak.resourceAccess[process.env.REACT_APP_KEYCLOAK_CLIENT_ID].roles
              groups: groups || [],
            },
          },
          updateUser: () => null,
          resetUser: () => null,
        }}
      >
        {props.children}
      </UserContext.Provider>
    );
  } else {
    return <PageBackdrop open={!allowed} />;
  }
};

const KeycloakProvider = (props: any) => {
  return (
    <SSRKeycloakProvider
      keycloakConfig={getKeycloakConfig()}
      initOptions={keycloakInitOptions}
      persistor={SSRCookies(props.cookies)}
      LoadingComponent={<PageBackdrop open={true} />}
      onTokens={onKeycloakTokens}
    >
      <KeycloakAC>{props.children}</KeycloakAC>
    </SSRKeycloakProvider>
  );
};

export default KeycloakProvider;
