import { useEffect, useState } from "react";
import { useKeycloak } from "@react-keycloak/ssr";

const useUserGroups = () => {
  const { keycloak } = useKeycloak();
  const [groups, setGroups] = useState<string[]>();

  useEffect(() => {
    // @ts-ignore
    const parsedToken = keycloak?.idTokenParsed;

    if (parsedToken) {
      // groups without leading '/'
      const groups = parsedToken?.groups
        ?.map((group: string) => group.replace(/^\//, ""))
        .filter((group: string) => !group.includes("/"));
      setGroups(groups);
    }
  }, [keycloak]);

  return groups;
};

export default useUserGroups;
