import React, { useCallback, useContext, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { useTranslations } from "next-intl";
import RefContext from "components/context/RefContext";
import TextField from "@mui/material/TextField";

const ResponseEditorLinkMenu = (props: any) => {
  const {
    config,
    translations,
    expanded,
    onExpandEvent,
    doExpand,
    doCollapse,
    currentState: { link, selectionText },
    onChange,
  } = props;
  const [open, setOpen] = React.useState(false);
  const [title, setTitle] = React.useState(selectionText);
  const [target, setTarget] = React.useState(link);
  const t = useTranslations();
  const ref = React.useRef(null);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const myRef = useContext(RefContext);
  const handleClose = (event: Event) => {
    setOpen(false);
    //
  };

  useEffect(() => {
    if (link) {
      if (link?.target) setTarget(link.target);
      if (link?.title) setTitle(link.title);
      else if (selectionText) setTitle(selectionText);
    } else if (selectionText) {
      setTitle(selectionText);
    }

    return () => {
      setTitle("");
      setTarget("");
    };
  }, [link, selectionText]);

  const handleSubmit = useCallback(() => {
    setOpen(false);
    if (title && target) {
      onChange("link", title, target, "_blank");
      setTitle("");
      setTarget("");
    }
  }, [title, target]);

  return (
    <>
      <IconButton
        sx={{ p: "2px", minWidth: "20px", m: "0 4px 6px 4px" }}
        onClick={() => {
          onExpandEvent();
          handleToggle();
        }}
        ref={ref}
      >
        <img src={config.link.icon} alt="" />
      </IconButton>
      <Popper
        open={open}
        transition
        placement="right-end"
        // @ts-ignore
        container={myRef.current}
        anchorEl={ref.current}
        sx={{ zIndex: 999999 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: "bottom left",
            }}
          >
            <Paper sx={{ width: 250, zIndex: 999999 }}>
              <ClickAwayListener onClickAway={handleClose}>
                <Box sx={{ p: 2 }}>
                  <Stack direction="column">
                    <Typography variant="subtitle2" color="grey.700">
                      {t("Link Titel")}
                    </Typography>
                    <TextField
                      size="small"
                      sx={{ width: "100%" }}
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                      onKeyDown={(ev) => {
                        if (ev.key === "Enter") {
                          handleSubmit();
                        }
                      }}
                    />
                  </Stack>
                  <Stack direction="column">
                    <Typography variant="subtitle2" color="grey.700">
                      {t("Link Ziel")}
                    </Typography>
                    <TextField
                      size="small"
                      sx={{ width: "100%" }}
                      value={target}
                      onChange={(e) => setTarget(e.target.value)}
                      onKeyDown={(ev) => {
                        if (ev.key === "Enter") {
                          handleSubmit();
                        }
                      }}
                    />
                  </Stack>
                  <Button onClick={handleSubmit}>Ok</Button>
                </Box>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default ResponseEditorLinkMenu;
